import request from '@/utils/request'


// 查询订单-商铺竞价记录列表
export function listShopAuction(query) {
  return request({
    url: '/order/shop-auction/list',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺竞价记录分页
export function pageShopAuction(query) {
  return request({
    url: '/order/shop-auction/page',
    method: 'get',
    params: query
  })
}

// 查询订单-商铺竞价记录详细
export function getShopAuction(data) {
  return request({
    url: '/order/shop-auction/detail',
    method: 'get',
    params: data
  })
}

// 新增订单-商铺竞价记录
export function addShopAuction(data) {
  return request({
    url: '/order/shop-auction/add',
    method: 'post',
    data: data
  })
}

// 修改订单-商铺竞价记录
export function updateShopAuction(data) {
  return request({
    url: '/order/shop-auction/edit',
    method: 'post',
    data: data
  })
}

// 删除订单-商铺竞价记录
export function delShopAuction(data) {
  return request({
    url: '/order/shop-auction/delete',
    method: 'post',
    data: data
  })
}
