<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="商铺id, res_shop.id" prop="shopId" >
        <a-input v-model="form.shopId" placeholder="请输入商铺id, res_shop.id" />
      </a-form-model-item>
      <a-form-model-item label="用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id" />
      </a-form-model-item>
      <a-form-model-item label="是否隐藏名字" prop="isHide" >
        <a-input v-model="form.isHide" placeholder="请输入是否隐藏名字" />
      </a-form-model-item>
      <a-form-model-item label="加价金额 " prop="addAmount" >
        <a-input v-model="form.addAmount" placeholder="请输入加价金额 " />
      </a-form-model-item>
      <a-form-model-item label="实际金额" prop="finalAmount" >
        <a-input v-model="form.finalAmount" placeholder="请输入实际金额" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getShopAuction, addShopAuction, updateShopAuction } from '@/api/order/shopAuction'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        shopId: null,

        userId: null,

        isHide: null,

        addAmount: null,

        finalAmount: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        shopId: [
          { required: true, message: '商铺id, res_shop.id不能为空', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '用户id不能为空', trigger: 'blur' }
        ],
        addAmount: [
          { required: true, message: '加价金额 不能为空', trigger: 'blur' }
        ],
        finalAmount: [
          { required: true, message: '实际金额不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        shopId: null,
        userId: null,
        isHide: null,
        addAmount: null,
        finalAmount: null,
        createTime: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getShopAuction({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateShopAuction(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addShopAuction(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
